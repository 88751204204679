import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import ListItem from "../ListItem/ListItem";
import StackGrid from "react-stack-grid";
import { withSize } from "react-sizeme";

const ListContainer = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 5px);
  margin-left: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
`;

const List = ({
  dataItems = [],
  onSelectedItem,
  size,
  columnWidth,
  showScore = true,
  showScoreInput,
}) => {
  const gridRef = useRef();
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    setTimeout(() => {
      setSelectedItem(null);
    }, 1000);
  }, []);

  return (
    <ListContainer name="ListContainer">
      <StackGrid
        columnWidth={
          columnWidth
            ? columnWidth
            : size.width < 700
            ? size.width - 22
            : size.width < 1400
            ? (size.width - 22) / 2
            : (size.width - 22) / 3
        }
        duration={1000}
        appearDelay={150}
        horizontal={true}
        gutterHeight={5}
        gutterWidth={5}
        rtl={true}
        ref={gridRef}
      >
        {dataItems
          ? dataItems.map((dataItem, ind) => (
              <ListItem
                height="3.9rem"
                key={dataItem.id}
                {...dataItem}
                isSelected={dataItem === selectedItem}
                showScore={showScore && !showScoreInput}
                showScoreInput={showScoreInput}
                onSelectedItem={() => {
                  if (onSelectedItem) {
                    if (selectedItem === dataItem) {
                      setSelectedItem(null);
                    } else {
                      setSelectedItem(dataItem);
                      const c = gridRef.current.grid.items[`.$${ind + 1}`].node;
                      setTimeout(() => {
                        if (c) {
                          c.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                            inline: "start",
                          });
                        }
                      }, 1000);
                    }
                    onSelectedItem(dataItem);
                  }
                }}
              />
            ))
          : ""}
      </StackGrid>
    </ListContainer>
  );
};

export default withSize()(List);
