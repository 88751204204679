import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import { withSize } from "react-sizeme";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { updateOptionRank } from "../../redux/actions/options_actions";

const debounceUpdateRank = debounce((dispatch, objectId, rank) => {
  dispatch(updateOptionRank(objectId, rank));
}, 500);

const ListItem = forwardRef((props, ref, size, updateRank) => {
  const [rankValue, setRank] = useState(props.rank);
  const dispatch = useDispatch();

  const handleRankChange = e => {
    if (e.target) {
      if (updateRank) {
        updateRank(props.objectId, e.target.value);
      }
      setRank(e.target.value);
    }
  };

  return (
    <ListItemContainer
      key={props.id}
      id={props.id}
      ref={ref}
      height={props.height}
      onClick={() => props.onSelectedItem()}
      addScoreColumn={props.showScore || props.showScoreInput}
      isSelected={props.isSelected}
    >
      {/* <SongImage src={props.imageUrl + props.id} /> */}
      <SongImage src={props.imageUrl} />
      <Content>
        <Title>{props.title}</Title>
        <SubTitle>{props.subTitle}</SubTitle>
      </Content>
      {props.showScore && !props.showScoreInput ? (
        <Score visible={props.showScore}>{props.rank}</Score>
      ) : (
        ""
      )}
      {props.showScoreInput ? (
        <InputDiv>
          <ScoreInput
            id="rankValue"
            name="rankValue"
            key="rankValue"
            className="input"
            type="number"
            visible={props.showScoreInput}
            onInput={handleRankChange}
            value={rankValue}
            autoComplete="off"
          ></ScoreInput>
          <InputBtn
            id="rankValueBtn"
            name="rankValueBtn"
            key="rankValueBtn"
            className="input"
            visible={props.showScoreInput}
            onClick={() =>
              debounceUpdateRank(dispatch, props.objectId, parseInt(rankValue))
            }
          >
            OK
          </InputBtn>
        </InputDiv>
      ) : (
        ""
      )}
    </ListItemContainer>
  );
});

export default withSize()(ListItem);

const ListItemContainer = styled.div`
  direction: rtl;
  display: grid;
  grid-template-columns: ${props =>
    props.addScoreColumn ? "7.5rem 1fr 10rem" : "7.5rem 1fr"};
  grid-template-rows: ${props => props.height};
  height: ${props => props.height};
  align-items: center;
  cursor: ${props => (props.onSelectedItem ? "pointer" : "default")};
  color: white;
  border: 1px solid #072a4c;
  transition: 0.75s background;
  background: ${props => (props.isSelected ? "#0d508b" : "#0c2c4f")};
  &:hover {
    background: #0d508b;
  }
`;

const SongImage = styled.img`
  grid-column: 1;
  height: 100%;
`;

const Content = styled.div`
  text-align: right;
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem 2rem;
  user-select: none;
`;

const Title = styled.div`
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubTitle = styled.div`
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Score = styled.div`
  grid-column: 3;
  margin-left: 1.25rem;
  text-align: left;
  font-size: 2rem;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`;

const ScoreInput = styled.input`
  width: 60%;
  font-size: 1.5rem;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`;

const InputBtn = styled.button`
  font-size: 1.5rem;
  width: 40%;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`;

const InputDiv = styled.div`
  display: flex;
  flex-direction: row;
  grid-column: 3;
  margin-left: 1.25rem;
  text-align: left;
  direction: ltr;
  font-size: 2rem;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`;
