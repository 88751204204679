import endpoints, { apiError, onSuccess } from "../../entities/endpoints";
import apiAction from "../middlewares/apiBaseAction";
import { API, GET_OPTIONS, UPDATE_OPTION_RANK } from "./types";

const { GET_OPTIONS_ENDPOINT, UPDATE_RANK } = endpoints;

export function getOptions() {
  const url = GET_OPTIONS_ENDPOINT;
  return apiAction({
    method: "GET",
    type: API,
    url,
    onSuccess: onSuccess(GET_OPTIONS),
    onFailure: apiError(GET_OPTIONS),
    label: GET_OPTIONS,
  });
}

export function updateOptionRank(objectId, rank) {
  const url = UPDATE_RANK;
  return apiAction({
    method: "PUT",
    type: API,
    url,
    data: {
      objectId,
      rank,
    },
    onSuccess: onSuccess(UPDATE_OPTION_RANK),
    onFailure: apiError(UPDATE_OPTION_RANK),
    label: UPDATE_OPTION_RANK,
  });
}
