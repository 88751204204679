const BASE_URL = `https://api.backendless.com/525C6ECF-BE35-D74F-FFC7-777E0FA29F00/3777A415-DFD8-4240-B51A-DCE496757AB7/data/Options`;

export default {
  GET_OPTIONS_ENDPOINT:
    BASE_URL + "?pageSize=100&sortBy=rank%20desc%2Cid%20asc",

  UPDATE_RANK: BASE_URL,
};

export function apiError(type, onError) {
  return error => {
    console.error("error :", error);

    if (onError) {
      onError(error);
    }

    return {
      type,
      payload: { error },
    };
  };
}

export function onSuccess(type, getPayloadFromData = x => x) {
  return data => ({
    type,
    payload: getPayloadFromData(data),
  });
}
