import { API, GET_OPTIONS } from "../actions/types";

export function options(
  state = {
    options: [],
  },
  action,
) {
  switch (action.type) {
    case GET_OPTIONS:
      return Object.assign({}, state, {
        options: action.payload,
      });
    default:
      return state;
  }
}
