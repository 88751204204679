import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import List from "../List/List.js";
import Player from "../Player/Player.js";
import { getOptions } from "../../redux/actions/options_actions";

const ContentWrapper = styled.div`
  background-color: rgba(31, 132, 201, 0.4);

  grid-row: 2;
  grid-column: 2;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: fit-content(0px) 1fr;
  grid-template-rows: 100%;
  grid-gap: 10px;
  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr;
    grid-template-rows: fit-content(0px) 1fr;
  }
`;

const ListPanel = styled.div`
  grid-row: 1;
  grid-column: 2;
  @media screen and (max-width: 1250px) {
    grid-row: 2;
    grid-column: 1;
  }
  width: 100%;
  margin: 10px;
  margin-left: -10px;
  position: relative;
  right: 0;
`;

const PlayerPanel = styled.div`
  grid-row: 1;
  grid-column: 1;
  height: 100%;
  width: ${props => (props.isExpanded ? "60vw" : "0px")};
  opacity: ${props => (props.isExpanded ? "1" : "0")};
  transition: opacity 1s ease-in-out;

  @media screen and (max-width: 1250px) {
    width: 100%;
    right: 0;
    left: 0;
    height: ${props => (props.isExpanded ? "40vw" : "0px")};
    transition: height 1s;
  }
`;

function MainView() {
  const optionsState = useSelector(state => state.options);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setInterval(() => {
      dispatch(getOptions());
    }, 10000);
  }, []);

  return (
    <ContentWrapper>
      <PlayerPanel isExpanded={isExpanded}>
        <Player
          youtubeVideoId={selectedItem ? selectedItem.youtubeVideoId : null}
        ></Player>
      </PlayerPanel>
      <ListPanel name="ListPanel" expandMode={isExpanded}>
        <List
          showScoreInput={false}
          showScore={true}
          dataItems={optionsState.options}
          onSelectedItem={di => {
            if (selectedItem === di) {
              setSelectedItem(null);
              setIsExpanded(false);
            } else {
              setSelectedItem(di);
              setIsExpanded(true);
            }
          }}
        ></List>
      </ListPanel>
    </ContentWrapper>
  );
}

export default MainView;
