import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { getOptions } from "../../redux/actions/options_actions";
import List from "../List/List";

const ContentWrapper = styled.div`
  background-color: rgba(31, 132, 201, 0.4);
  grid-row: 2;
  grid-column: 2;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: fit-content(0px) 1fr;
  grid-template-rows: 100%;
  grid-gap: 10px;

  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr;
    grid-template-rows: fit-content(0px) 1fr;
  }
`;

const ListPanel = styled.div`
  grid-row: 1;
  grid-column: 2;
  @media screen and (max-width: 1250px) {
    grid-row: 2;
    grid-column: 1;
  }
  width: 100%;
  margin: 10px;
  margin-left: -10px;
  position: relative;
  right: 0;
`;

function ScoringPanel() {
  const optionsState = useSelector(state => state.options);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOptions());
  }, []);

  return (
    <ContentWrapper>
      <ListPanel>
        <List
          showScoreInput={true}
          showScore={false}
          columnWidth="100%"
          dataItems={optionsState.options}
        ></List>
      </ListPanel>
    </ContentWrapper>
  );
}

export default ScoringPanel;
