import { API } from "../actions/types";

export default function apiAction({
  url = "",
  method = "",
  data = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = "",
  onUploadProgress,
  optObj = false,
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      onSuccess,
      onFailure,
      label,
      onUploadProgress,
      optObj,
    },
  };
}
