import React, { forwardRef } from "react";
import styled from "styled-components";
import YouTube from "react-youtube";

const Player = ({ youtubeVideoId }) => {
  return (
    <PlayerContainer>
      <PlayerDiv>
        <YouTube
          videoId={youtubeVideoId}
          containerClassName="player"
          opts={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignSelf: "center",
          }}
        />
      </PlayerDiv>
      {/* <InfoPanel></InfoPanel> */}
    </PlayerContainer>
  );
};

export default Player;

const PlayerContainer = styled.div`
  direction: rtl;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin: 1rem;
  margin-top: 1.25rem;
  height: 95%;
  align-self: center;

  @media screen and (max-width: 1000px) {
    margin-top: 1rem;
  }
`;

const PlayerDiv = styled.div`
  grid-row: 1;
  height: 100%;
  width: 100%;
`;

const InfoPanel = styled.div`
  grid-row: 2;
  height: 100%;
  width: 100%;
`;
