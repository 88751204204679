import React, { useEffect } from "react";
import styled from "styled-components";
import "./App.css";
import { getOptions } from "./redux/actions/options_actions";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainView from "./Components/MainView/MainView";
import ScoringPanel from "./Components/ScoringPanel/ScoringPanel";
import { useDispatch } from "react-redux";

const Layout = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-template-rows: 50px 1fr 50px;
`;

const ContentWrapper = styled.div`
  background-color: rgba(31, 132, 201, 0.4);

  grid-row: 2;
  grid-column: 2;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: fit-content(0px) 1fr;
  grid-template-rows: 100%;
  grid-gap: 10px;
  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr;
    grid-template-rows: fit-content(0px) 1fr;
  }
`;

const ListPanel = styled.div`
  grid-row: 1;
  grid-column: 2;
  @media screen and (max-width: 1250px) {
    grid-row: 2;
    grid-column: 1;
  }
  width: 100%;
  margin: 10px;
  margin-left: -10px;
  position: relative;
  right: 0;
`;

const PlayerPanel = styled.div`
  grid-row: 1;
  grid-column: 1;
  height: 100%;
  width: ${props => (props.isExpanded ? "60vw" : "0px")};
  opacity: ${props => (props.isExpanded ? "1" : "0")};
  transition: opacity 1s ease-in-out;

  @media screen and (max-width: 1250px) {
    width: 100%;
    right: 0;
    left: 0;
    height: ${props => (props.isExpanded ? "40vw" : "0px")};
    transition: height 1s;
  }
`;

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOptions());
  }, []);

  return (
    <Router className={"test"}>
      <Route
        path="/"
        render={props => {
          console.log(window.location);
          if (window.location.href.toLowerCase().includes("scoring")) {
            return (
              <Layout>
                <ScoringPanel></ScoringPanel>
              </Layout>
            );
          } else {
            return (
              <Layout>
                <MainView></MainView>
              </Layout>
            );
          }
        }}
      />
    </Router>
  );
}

export default App;
